import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import PropTypes from 'prop-types';

const MessageTo = ({ message, options }) => {
  if (!message) {
    return null;
  }
  const {
    recipientAlias,
    recipientName,
    id,
    recipientGroups,
    recipientMeta,
    recipientCount,
    createRequestId,
  } = message;

  const { useAdminView = false } = options;
  let toIcon = null;
  let title = `Alias: ${recipientAlias || '-'}`;
  let to = recipientAlias;

  if (useAdminView && !(recipientGroups || []).includes('Admin')) {
    toIcon = <FontAwesomeIcon icon={icon({ name: 'user-secret', style: 'duotone' })} />;
    title = `Real name: ${recipientName || '-'}`;
  } else if (useAdminView && (recipientGroups || []).includes('Admin')) {
    to = recipientName;
    // title = null;
    // title = `Real name: ${recipientName || '-'}`;
  }
  if (recipientCount && recipientCount > 1) {
    to = `${recipientCount} Recipients`;
    let details = `${recipientCount} Recipients`;
    const tipId = `${createRequestId}-data`;
    toIcon = <FontAwesomeIcon icon={icon({ name: 'users', style: 'solid' })} />;
    const maxSummaryRecipients = 5;
    if (recipientMeta && Array.isArray(recipientMeta.users)) {
      const recipientList = recipientMeta.users
        .slice(0, maxSummaryRecipients)
        .map(x => x.alias)
        .join(', ');
      details = `Recipients: ${recipientList}${
        recipientCount > maxSummaryRecipients ? `and ${recipientCount - maxSummaryRecipients} more` : ''
      }`;
    }
    return (
      <span className="relative">
        <span aria-describedby={tipId}>
          {toIcon} {to || '-'}
        </span>
        <span role="tooltip" className="p-4 rounded-md bg-gray-100 text-sm text-gray-400 w-64" id={tipId}>
          {details}
        </span>
      </span>
    );
  }
  return (
    <span title={title}>
      {toIcon} {to || '-'}
      {/* {JSON.stringify(message, null, 2)} */}
    </span>
  );
};

MessageTo.propTypes = {
  message: PropTypes.object.isRequired,
  options: PropTypes.object,
};

export default MessageTo;
