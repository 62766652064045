/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser {
    getUser {
      id
      identityId
      email
      alias
      firstName
      lastName
      dateOfBirth
      country
      state
      zip
      heardAboutUs
      watchedIntroVideo
      userStatus
      certificatePath
      _deleted
      lastSignInAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const getUserById = /* GraphQL */ `
  query GetUserById($id: ID) {
    getUserById(id: $id) {
      id
      identityId
      email
      alias
      firstName
      lastName
      dateOfBirth
      country
      state
      zip
      heardAboutUs
      watchedIntroVideo
      userStatus
      certificatePath
      _deleted
      lastSignInAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const getLeoUserById = /* GraphQL */ `
  query GetLeoUserById($id: ID) {
    getLeoUserById(id: $id) {
      id
      identityId
      email
      firstName
      lastName
      country
      state
      zip
      agency
      position
      department
      city
      downloads
      userStatus
      _deleted
      lastSignInAt
      lastDownloadedAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const listVolunteers = /* GraphQL */ `
  query ListVolunteers(
    $userStatus: UserStatus
    $searchTerm: String
    $nextToken: NextUserInput
    $sortField: UserSortField
    $sortOrder: SortDirection
    $limit: Int
  ) {
    listVolunteers(
      userStatus: $userStatus
      searchTerm: $searchTerm
      nextToken: $nextToken
      sortField: $sortField
      sortOrder: $sortOrder
      limit: $limit
    ) {
      items {
        id
        identityId
        email
        alias
        firstName
        lastName
        dateOfBirth
        country
        state
        zip
        heardAboutUs
        watchedIntroVideo
        userStatus
        certificatePath
        _deleted
        lastSignInAt
        approvedAt
        disabledAt
        createdAt
        updatedAt
      }
      nextToken {
        id
        lastName
        isEmployee
        email
        alias
        firstName
        state
        userStatus
        lastSignInAt
        createdAt
      }
    }
  }
`;
export const listEmployees = /* GraphQL */ `
  query ListEmployees(
    $userStatus: UserStatus
    $searchTerm: String
    $nextToken: NextUserInput
    $sortField: UserSortField
    $sortOrder: SortDirection
    $limit: Int
  ) {
    listEmployees(
      userStatus: $userStatus
      searchTerm: $searchTerm
      nextToken: $nextToken
      sortField: $sortField
      sortOrder: $sortOrder
      limit: $limit
    ) {
      items {
        id
        identityId
        email
        alias
        firstName
        lastName
        dateOfBirth
        country
        state
        zip
        heardAboutUs
        watchedIntroVideo
        userStatus
        certificatePath
        _deleted
        lastSignInAt
        approvedAt
        disabledAt
        createdAt
        updatedAt
      }
      nextToken {
        id
        lastName
        isEmployee
        email
        alias
        firstName
        state
        userStatus
        lastSignInAt
        createdAt
      }
    }
  }
`;
export const getCase = /* GraphQL */ `
  query GetCase($id: ID!) {
    getCase(id: $id) {
      id
      caseNumber
      sequentialNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          dateOfBirth
          email
          cashtag
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          dateOfBirth
          email
          cashtag
        }
        description
        author
        previousSourceId
      }
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listOwnedCases = /* GraphQL */ `
  query ListOwnedCases(
    $status: [CaseStatus]
    $searchTerm: String
    $nextToken: NextCaseInput
    $sortField: CaseSortField
    $sortOrder: SortDirection
    $limit: Int
  ) {
    listOwnedCases(
      status: $status
      searchTerm: $searchTerm
      nextToken: $nextToken
      sortField: $sortField
      sortOrder: $sortOrder
      limit: $limit
    ) {
      items {
        id
        caseNumber
        sequentialNumber
        owner
        ownerAlias
        assignedTo
        assignedToName
        firstName
        middleName
        lastName
        age
        city
        state
        details
        escortAd {
          url
          phones {
            id
            type
            number
          }
          description
        }
        webSources {
          id
          website
          url
          uniqueFields {
            title
            username
            userId
            displayName
            profileName
            profilePictureUrl
            registeredPhoneNumber
            dateOfBirth
            email
            cashtag
          }
          description
        }
        staffSources {
          id
          website
          url
          uniqueFields {
            title
            username
            userId
            displayName
            profileName
            profilePictureUrl
            registeredPhoneNumber
            dateOfBirth
            email
            cashtag
          }
          description
          author
          previousSourceId
        }
        status
        investigatorNotes
        lat
        lon
        mergedParentId
        reportImages {
          id
          s3Key
        }
        reportKey
        breakpoints
        submittedAt
        returnedAt
        approvedAt
        yearApproved
        wasReturned
        returnCount
        awardClassificationOption
        feedbackComments {
          stepId
          feedback
        }
        awardedPoints {
          stepId
          awardType
        }
        totalPoints {
          pointsAwarded
          fullPoints
          isFullPoints
        }
        _deleted
        createdAt
        updatedAt
        deletedAt
      }
      nextToken {
        id
        createdAt
        submittedAt
        assignedTo
        status
        owner
        caseNumber
        sequentialNumber
        ownerAlias
        firstName
        lastName
        state
      }
    }
  }
`;
export const listAssignedCases = /* GraphQL */ `
  query ListAssignedCases(
    $status: CaseStatus
    $searchTerm: String
    $nextToken: NextCaseInput
    $sortField: CaseSortField
    $sortOrder: SortDirection
    $limit: Int
  ) {
    listAssignedCases(
      status: $status
      searchTerm: $searchTerm
      nextToken: $nextToken
      sortField: $sortField
      sortOrder: $sortOrder
      limit: $limit
    ) {
      items {
        id
        caseNumber
        sequentialNumber
        owner
        ownerAlias
        assignedTo
        assignedToName
        firstName
        middleName
        lastName
        age
        city
        state
        details
        escortAd {
          url
          phones {
            id
            type
            number
          }
          description
        }
        webSources {
          id
          website
          url
          uniqueFields {
            title
            username
            userId
            displayName
            profileName
            profilePictureUrl
            registeredPhoneNumber
            dateOfBirth
            email
            cashtag
          }
          description
        }
        staffSources {
          id
          website
          url
          uniqueFields {
            title
            username
            userId
            displayName
            profileName
            profilePictureUrl
            registeredPhoneNumber
            dateOfBirth
            email
            cashtag
          }
          description
          author
          previousSourceId
        }
        status
        investigatorNotes
        lat
        lon
        mergedParentId
        reportImages {
          id
          s3Key
        }
        reportKey
        breakpoints
        submittedAt
        returnedAt
        approvedAt
        yearApproved
        wasReturned
        returnCount
        awardClassificationOption
        feedbackComments {
          stepId
          feedback
        }
        awardedPoints {
          stepId
          awardType
        }
        totalPoints {
          pointsAwarded
          fullPoints
          isFullPoints
        }
        _deleted
        createdAt
        updatedAt
        deletedAt
      }
      nextToken {
        id
        createdAt
        submittedAt
        assignedTo
        status
        owner
        caseNumber
        sequentialNumber
        ownerAlias
        firstName
        lastName
        state
      }
    }
  }
`;
export const listCasesForAdmin = /* GraphQL */ `
  query ListCasesForAdmin(
    $status: CaseStatus
    $assignedTo: String
    $searchTerm: String
    $nextToken: NextCaseInput
    $sortField: CaseSortField
    $sortOrder: SortDirection
    $limit: Int
  ) {
    listCasesForAdmin(
      status: $status
      assignedTo: $assignedTo
      searchTerm: $searchTerm
      nextToken: $nextToken
      sortField: $sortField
      sortOrder: $sortOrder
      limit: $limit
    ) {
      items {
        id
        caseNumber
        sequentialNumber
        owner
        ownerAlias
        assignedTo
        assignedToName
        firstName
        middleName
        lastName
        age
        city
        state
        details
        escortAd {
          url
          phones {
            id
            type
            number
          }
          description
        }
        webSources {
          id
          website
          url
          uniqueFields {
            title
            username
            userId
            displayName
            profileName
            profilePictureUrl
            registeredPhoneNumber
            dateOfBirth
            email
            cashtag
          }
          description
        }
        staffSources {
          id
          website
          url
          uniqueFields {
            title
            username
            userId
            displayName
            profileName
            profilePictureUrl
            registeredPhoneNumber
            dateOfBirth
            email
            cashtag
          }
          description
          author
          previousSourceId
        }
        status
        investigatorNotes
        lat
        lon
        mergedParentId
        reportImages {
          id
          s3Key
        }
        reportKey
        breakpoints
        submittedAt
        returnedAt
        approvedAt
        yearApproved
        wasReturned
        returnCount
        awardClassificationOption
        feedbackComments {
          stepId
          feedback
        }
        awardedPoints {
          stepId
          awardType
        }
        totalPoints {
          pointsAwarded
          fullPoints
          isFullPoints
        }
        _deleted
        createdAt
        updatedAt
        deletedAt
      }
      nextToken {
        id
        createdAt
        submittedAt
        assignedTo
        status
        owner
        caseNumber
        sequentialNumber
        ownerAlias
        firstName
        lastName
        state
      }
    }
  }
`;
export const getMergedCase = /* GraphQL */ `
  query GetMergedCase($id: String) {
    getMergedCase(id: $id) {
      caseNumbers
      sources
      sequentialNumber
    }
  }
`;
export const getCasesByNo = /* GraphQL */ `
  query GetCasesByNo($caseNoList: String) {
    getCasesByNo(caseNoList: $caseNoList) {
      id
      caseNumber
      sequentialNumber
      owner
      ownerAlias
      assignedTo
      assignedToName
      firstName
      middleName
      lastName
      age
      city
      state
      details
      escortAd {
        url
        phones {
          id
          type
          number
        }
        description
      }
      webSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          dateOfBirth
          email
          cashtag
        }
        description
      }
      staffSources {
        id
        website
        url
        uniqueFields {
          title
          username
          userId
          displayName
          profileName
          profilePictureUrl
          registeredPhoneNumber
          dateOfBirth
          email
          cashtag
        }
        description
        author
        previousSourceId
      }
      status
      investigatorNotes
      lat
      lon
      mergedParentId
      reportImages {
        id
        s3Key
      }
      reportKey
      breakpoints
      submittedAt
      returnedAt
      approvedAt
      yearApproved
      wasReturned
      returnCount
      awardClassificationOption
      feedbackComments {
        stepId
        feedback
      }
      awardedPoints {
        stepId
        awardType
      }
      totalPoints {
        pointsAwarded
        fullPoints
        isFullPoints
      }
      _deleted
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const listTutorials = /* GraphQL */ `
  query ListTutorials(
    $searchTerm: String
    $category: String
    $nextToken: NextTutorialInput
    $sortField: TutorialSortField
    $sortOrder: SortDirection
    $limit: Int
  ) {
    listTutorials(
      searchTerm: $searchTerm
      category: $category
      nextToken: $nextToken
      sortField: $sortField
      sortOrder: $sortOrder
      limit: $limit
    ) {
      items {
        s3Key
        thumbnailS3Key
        time
        title
        description
        dateUploaded
        author
        authorName
        category
      }
      nextToken {
        s3Key
        dateUploaded
        time
        title
        description
        authorName
        category
      }
    }
  }
`;
export const getNotificationCount = /* GraphQL */ `
  query GetNotificationCount {
    getNotificationCount
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $nextToken: NextNotificationInput
    $sortOrder: SortDirection
    $limit: Int
  ) {
    listNotifications(
      nextToken: $nextToken
      sortOrder: $sortOrder
      limit: $limit
    ) {
      items {
        id
        title
        message
        type
        recipient
        createdAt
        updatedAt
      }
      nextToken {
        id
        recipient
        createdAt
      }
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $nextToken: NextMessageInput
    $sortField: MessageSortField
    $sortOrder: SortDirection
    $limit: Int
  ) {
    listMessages(
      nextToken: $nextToken
      sortField: $sortField
      sortOrder: $sortOrder
      limit: $limit
    ) {
      items {
        id
        title
        message
        subject
        type
        recipient
        recipientAlias
        recipientName
        recipientCount
        recipientMeta {
          recipientCount
          seenCount
          readCount
          users {
            id
            alias
            name
            seenAt
          }
        }
        sender
        senderAlias
        senderName
        senderGroups
        recipientGroups
        createRequestId
        replyTo
        readAt
        seenAt
        adminFlaggedAt
        assignedTo
        assignedToName
        createdAt
        updatedAt
        replyPath
        rootMessageId
        attributes {
          startDate
          endDate
          customTitle
          includeMessage
          fileAttachments {
            name
            path
            size
            type
          }
        }
      }
      nextToken {
        id
        recipient
        createdAt
      }
    }
  }
`;
export const listAllMessages = /* GraphQL */ `
  query ListAllMessages(
    $nextToken: NextMessageInput
    $sortOrder: SortDirection
    $sortField: MessageSortField
    $limit: Int
  ) {
    listAllMessages(
      nextToken: $nextToken
      sortOrder: $sortOrder
      sortField: $sortField
      limit: $limit
    ) {
      items {
        id
        title
        message
        subject
        type
        recipient
        recipientAlias
        recipientName
        recipientCount
        recipientMeta {
          recipientCount
          seenCount
          readCount
          users {
            id
            alias
            name
            seenAt
          }
        }
        sender
        senderAlias
        senderName
        senderGroups
        recipientGroups
        createRequestId
        replyTo
        readAt
        seenAt
        adminFlaggedAt
        assignedTo
        assignedToName
        createdAt
        updatedAt
        replyPath
        rootMessageId
        attributes {
          startDate
          endDate
          customTitle
          includeMessage
          fileAttachments {
            name
            path
            size
            type
          }
        }
      }
      nextToken {
        id
        recipient
        createdAt
      }
    }
  }
`;
export const listGroupMessages = /* GraphQL */ `
  query ListGroupMessages(
    $nextToken: NextMessageInput
    $group: String
    $sortOrder: SortDirection
    $sortField: MessageSortField
    $limit: Int
  ) {
    listGroupMessages(
      nextToken: $nextToken
      group: $group
      sortOrder: $sortOrder
      sortField: $sortField
      limit: $limit
    ) {
      items {
        id
        title
        message
        subject
        type
        recipient
        recipientAlias
        recipientName
        recipientCount
        recipientMeta {
          recipientCount
          seenCount
          readCount
          users {
            id
            alias
            name
            seenAt
          }
        }
        sender
        senderAlias
        senderName
        senderGroups
        recipientGroups
        createRequestId
        replyTo
        readAt
        seenAt
        adminFlaggedAt
        assignedTo
        assignedToName
        createdAt
        updatedAt
        replyPath
        rootMessageId
        attributes {
          startDate
          endDate
          customTitle
          includeMessage
          fileAttachments {
            name
            path
            size
            type
          }
        }
      }
      nextToken {
        id
        recipient
        createdAt
      }
    }
  }
`;
export const sentMessages = /* GraphQL */ `
  query SentMessages(
    $nextToken: NextMessageInput
    $sortField: MessageSortField
    $sortOrder: SortDirection
    $limit: Int
  ) {
    sentMessages(
      nextToken: $nextToken
      sortField: $sortField
      sortOrder: $sortOrder
      limit: $limit
    ) {
      items {
        id
        title
        message
        subject
        type
        recipient
        recipientAlias
        recipientName
        recipientCount
        recipientMeta {
          recipientCount
          seenCount
          readCount
          users {
            id
            alias
            name
            seenAt
          }
        }
        sender
        senderAlias
        senderName
        senderGroups
        recipientGroups
        createRequestId
        replyTo
        readAt
        seenAt
        adminFlaggedAt
        assignedTo
        assignedToName
        createdAt
        updatedAt
        replyPath
        rootMessageId
        attributes {
          startDate
          endDate
          customTitle
          includeMessage
          fileAttachments {
            name
            path
            size
            type
          }
        }
      }
      nextToken {
        id
        recipient
        createdAt
      }
    }
  }
`;
export const sentGroupMessages = /* GraphQL */ `
  query SentGroupMessages(
    $nextToken: NextMessageInput
    $group: String
    $sortField: MessageSortField
    $sortOrder: SortDirection
    $limit: Int
  ) {
    sentGroupMessages(
      nextToken: $nextToken
      group: $group
      sortField: $sortField
      sortOrder: $sortOrder
      limit: $limit
    ) {
      items {
        id
        title
        message
        subject
        type
        recipient
        recipientAlias
        recipientName
        recipientCount
        recipientMeta {
          recipientCount
          seenCount
          readCount
          users {
            id
            alias
            name
            seenAt
          }
        }
        sender
        senderAlias
        senderName
        senderGroups
        recipientGroups
        createRequestId
        replyTo
        readAt
        seenAt
        adminFlaggedAt
        assignedTo
        assignedToName
        createdAt
        updatedAt
        replyPath
        rootMessageId
        attributes {
          startDate
          endDate
          customTitle
          includeMessage
          fileAttachments {
            name
            path
            size
            type
          }
        }
      }
      nextToken {
        id
        recipient
        createdAt
      }
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      title
      message
      subject
      type
      recipient
      recipientAlias
      recipientName
      recipientCount
      recipientMeta {
        recipientCount
        seenCount
        readCount
        users {
          id
          alias
          name
          seenAt
        }
      }
      sender
      senderAlias
      senderName
      senderGroups
      recipientGroups
      createRequestId
      replyTo
      readAt
      seenAt
      adminFlaggedAt
      assignedTo
      assignedToName
      createdAt
      updatedAt
      replyPath
      rootMessageId
      attributes {
        startDate
        endDate
        customTitle
        includeMessage
        fileAttachments {
          name
          path
          size
          type
        }
      }
    }
  }
`;
export const viewMessage = /* GraphQL */ `
  query ViewMessage($id: ID!) {
    viewMessage(id: $id) {
      id
      title
      message
      subject
      type
      recipient
      recipientAlias
      recipientName
      recipientCount
      recipientMeta {
        recipientCount
        seenCount
        readCount
        users {
          id
          alias
          name
          seenAt
        }
      }
      sender
      senderAlias
      senderName
      senderGroups
      recipientGroups
      createRequestId
      replyTo
      readAt
      seenAt
      adminFlaggedAt
      assignedTo
      assignedToName
      createdAt
      updatedAt
      replyPath
      rootMessageId
      attributes {
        startDate
        endDate
        customTitle
        includeMessage
        fileAttachments {
          name
          path
          size
          type
        }
      }
    }
  }
`;
export const listMessageThreads = /* GraphQL */ `
  query ListMessageThreads($id: ID!) {
    listMessageThreads(id: $id) {
      items {
        id
        title
        message
        subject
        type
        recipient
        recipientAlias
        recipientName
        recipientCount
        recipientMeta {
          recipientCount
          seenCount
          readCount
          users {
            id
            alias
            name
            seenAt
          }
        }
        sender
        senderAlias
        senderName
        senderGroups
        recipientGroups
        createRequestId
        replyTo
        readAt
        seenAt
        adminFlaggedAt
        assignedTo
        assignedToName
        createdAt
        updatedAt
        replyPath
        rootMessageId
        attributes {
          startDate
          endDate
          customTitle
          includeMessage
          fileAttachments {
            name
            path
            size
            type
          }
        }
      }
      nextToken {
        replyPath
      }
    }
  }
`;
export const getTopLeaderboard = /* GraphQL */ `
  query GetTopLeaderboard($board: String!) {
    getTopLeaderboard(board: $board) {
      rankings {
        id
        rank
        alias
        leadsApproved
        leadsSubmitted
        totalPoints
        hoursSpent
      }
    }
  }
`;
export const getUserRank = /* GraphQL */ `
  query GetUserRank($board: String!, $id: ID!) {
    getUserRank(board: $board, id: $id)
  }
`;
export const getUserLeaderboardSummary = /* GraphQL */ `
  query GetUserLeaderboardSummary($board: String!, $id: ID) {
    getUserLeaderboardSummary(board: $board, id: $id) {
      rank
      points
      pointsPercent
      leadsApproved
      leadsSubmitted
    }
  }
`;
export const getCaseMapData = /* GraphQL */ `
  query GetCaseMapData($board: String, $id: ID) {
    getCaseMapData(board: $board, id: $id) {
      userCases {
        name
        lat
        lon
      }
      crowdCases {
        name
        lat
        lon
      }
      userOver18Cases {
        name
        lat
        lon
      }
      crowdOver18Cases {
        name
        lat
        lon
      }
    }
  }
`;
export const getHistoryStatistics = /* GraphQL */ `
  query GetHistoryStatistics($board: String!, $id: ID) {
    getHistoryStatistics(board: $board, id: $id) {
      leadsApproved {
        time
        value
      }
      leadsSubmitted {
        time
        value
      }
      userLeadsApproved {
        time
        value
      }
      userLeadsSubmitted {
        time
        value
      }
      usersTotal {
        time
        value
      }
      usersNew {
        time
        value
      }
      hoursCrowd {
        time
        value
      }
      hoursUser {
        time
        value
      }
    }
  }
`;
export const listLeos = /* GraphQL */ `
  query ListLeos(
    $userStatus: UserStatus
    $searchTerm: String
    $nextToken: NextUserInput
    $sortField: UserSortField
    $sortOrder: SortDirection
    $limit: Int
  ) {
    listLeos(
      userStatus: $userStatus
      searchTerm: $searchTerm
      nextToken: $nextToken
      sortField: $sortField
      sortOrder: $sortOrder
      limit: $limit
    ) {
      items {
        id
        identityId
        email
        firstName
        lastName
        country
        state
        zip
        agency
        position
        department
        city
        downloads
        userStatus
        _deleted
        lastSignInAt
        lastDownloadedAt
        approvedAt
        disabledAt
        createdAt
        updatedAt
      }
      nextToken {
        id
        lastName
        isEmployee
        email
        alias
        firstName
        state
        userStatus
        lastSignInAt
        createdAt
      }
    }
  }
`;
