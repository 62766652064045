const awsmobile = {
  "aws_project_region": "us-west-2",
  "aws_cognito_region": "us-west-2",
  "aws_appsync_region": "us-west-2",
  "aws_user_files_s3_bucket_region": "us-west-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_cognito_login_mechanisms": [
    "PREFERRED_USERNAME"
  ],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
    "SMS"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ],
  "aws_cognito_identity_pool_id": "us-west-2:27a1d38d-7f3a-4478-b73f-c2064dc996a4",
  "aws_user_pools_id": "us-west-2_svpOOAbTO",
  "aws_user_pools_web_client_id": "35lope8kbe1ri19slnebavf9t6",
  "aws_appsync_graphqlEndpoint": "https://aoue6tlvpvdylh2wn4tgoluwee.appsync-api.us-west-2.amazonaws.com/graphql",
  "aws_user_files_s3_bucket": "pursuit-storage-testing"
}; 

export default awsmobile;