export const websiteList = [
  'CashApp',
  'Date of Birth',
  'Email',
  'Facebook',
  'Instagram',
  'Kik',
  'Messenger',
  'PayPal',
  'Periscope',
  'Pinterest',
  'Reddit',
  'Snapchat',
  'Sound Cloud',
  'Telegram',
  'TikTok',
  'Tumblr',
  'Twitch',
  'Twitter',
  'Venmo',
  'Website',
  'WhatsApp',
  'YouTube',
  'Other',
];
export const webSourceRequiredFields = ['url', 'description'];

export const webSourceFields = {
  cashapp: {
    cashtag: '',
    displayName: '',
    profilePictureUrl: '',
    registeredPhoneNumber: '',
    url: '',
  },
  dateofbirth: {
    dateOfBirth: '',
  },
  email: {
    email: '',
  },
  facebook: {
    profileName: '',
    userId: '',
    username: '',
  },
  instagram: {
    displayName: '',
    userId: '',
    username: '',
  },
  kik: {
    displayName: '',
    username: '',
  },
  messenger: {
    username: '',
  },

  paypal: {
    displayName: '',
    registeredPhoneNumber: '',
    username: '',
  },
  periscope: {
    displayName: '',
    username: '',
  },
  pinterest: {
    displayName: '',
    username: '',
  },
  reddit: {
    username: '',
  },
  snapchat: {
    displayName: '',
    username: '',
  },
  soundcloud: {
    displayName: '',
    username: '',
  },
  telegram: {
    displayName: '',
    registeredPhoneNumber: '',
    username: '',
  },
  tiktok: {
    displayName: '',
    userId: '',
    username: '',
  },
  tumblr: {
    displayName: '',
    username: '',
  },
  twitch: {
    displayName: '',
    username: '',
  },
  twitter: {
    displayName: '',
    username: '',
  },
  venmo: {
    displayName: '',
    registeredPhoneNumber: '',
    username: '',
  },
  website: {
    displayName: '',
    title: '',
    username: '',
  },
  whatsapp: {
    displayName: '',
    registeredPhoneNumber: '',
    username: '',
  },
  youtube: {
    displayName: '',
    username: '',
  },
  other: {
    displayName: '',
    title: '',
    userId: '',
    username: '',
  },
};

// Pretty labels to show next to fields
export const webSourceFieldLabels = {
  url: 'URL',
  description: 'Description',
  title: 'Title',
  username: 'Username',
  displayName: 'Display Name',
  userId: 'User ID',
  dateOfBirth: 'Date of Birth',
  email: 'Email',
  cashtag: 'Cashtag',
  profileName: 'Profile Name',
  profilePictureUrl: 'Profile Picture URL',
  registeredPhoneNumber: 'Registered Phone Number',
};

// Hint text to show inside fields
export const webSourceFieldPlaceholders = {
  url: 'Paste URL',
  description: 'Explain how you got to this point and your analysis',
  registeredPhoneNumber: '555-555-5555',
};
