import React, { useState } from 'react';
import * as Icons from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import Feedback from '../Feedback';
import Button from '../Button';
import PhoneField from '../PhoneField';
import PopUpConfirmation from '../PopupConfirmation';
import TrashIcon from '../../assets/Images/trash.svg';
import { ESCORT_AD } from '../../constants';

const EscortAdForm = ({ caseState, setCaseState, saveAndAddButton, reviewing }) => {
  const [phoneIndexToRemove, setPhoneIndexToRemove] = useState(null);

  const newPhone = {
    type: 'Cellular',
    number: '',
  };

  const setPhoneNumber = (index, key, value) => {
    setCaseState({
      ...caseState,
      escortAd: {
        ...caseState.escortAd,
        phones: caseState.escortAd.phones.map((phone, i) => (i === index ? { ...phone, [key]: value } : phone)),
      },
    });
  };

  const removePhoneNumber = index => {
    setPhoneIndexToRemove(null);

    const updatedPhones = [...caseState.escortAd.phones];

    updatedPhones.splice(index, 1);

    const newCaseState = {
      ...caseState,
      escortAd: { ...caseState.escortAd, phones: updatedPhones },
    };

    setCaseState(newCaseState);
  };

  const setInput = (key, value) => {
    setCaseState({ ...caseState, escortAd: { ...caseState.escortAd, [key]: value } });
  };

  const addPhoneNumber = () => {
    setCaseState({
      ...caseState,
      escortAd: { ...caseState.escortAd, phones: [...caseState.escortAd.phones, { ...newPhone, id: uuidv4() }] },
    });
  };

  return (
    <>
      {phoneIndexToRemove && (
        <PopUpConfirmation
          title="Remove Phone Number"
          content={`Are you sure you want to remove ${
            caseState.escortAd.phones[phoneIndexToRemove].number
              ? caseState.escortAd.phones[phoneIndexToRemove].number
              : 'this phone number'
          }?`}
          onCancel={() => setPhoneIndexToRemove(null)}
          onConfirm={() => removePhoneNumber(phoneIndexToRemove)}
          confirmText="DELETE"
          destructive
          className="w-11/12 lg:w-auto"
        />
      )}
      <div className="h-full mb-8">
        {reviewing ? (
          <h2 className="font-bold text-lg text-pursuit-gray">Source 1 - Details from Escort Ad</h2>
        ) : (
          <h2 className="font-bold text-lg text-pursuit-gray">Add Source 1 - Add Details from Escort Ad</h2>
        )}
        <div className="text-sm text-pursuit-gray">
          {!reviewing && (
            <p>
              Fill out as much info as you can about this source. You can add additional sources by clicking the 'Save
              and Add New Source' button. Once you have added all of your sources, proceed to the next page to review
              your details and submit your case for review.
            </p>
          )}
        </div>
      </div>
      <div className="h-full flex flex-col">
        <label className="text-pursuit-gray">
          URL<span className="text-pursuit-red ml-1">*</span>
        </label>
        <input
          type="text"
          placeholder="Paste URL"
          className="mt-2 mb-4 px-2 h-10 lg:w-2/3 2xl:w-1/2 shadow"
          value={caseState.escortAd.url}
          onChange={event => {
            setInput('url', event.target.value);
          }}
        />

        {caseState && caseState.escortAd && caseState.escortAd.phones
          ? caseState.escortAd.phones.map((phone, idx) => (
              <div key={`phone-${phone?.id}`} className="flex flex-col lg:flex-row mb-2">
                <div className="flex flex-col lg:mr-4 lg:w-40">
                  <label className="text-pursuit-gray">
                    Phone Number<span className="text-pursuit-red ml-1">*</span>
                  </label>
                  <PhoneField
                    className="mt-2 mb-4 px-2 h-10 shadow"
                    placeholder="555-555-5555"
                    value={caseState.escortAd.phones[idx].number}
                    setState={phoneNumber => {
                      setPhoneNumber(idx, 'number', phoneNumber);
                    }}
                  />
                </div>

                <div className="flex flex-col lg:ml-4 lg:w-40">
                  <label className="text-pursuit-gray">
                    Type<span className="text-pursuit-red ml-1">*</span>
                  </label>
                  <select
                    className="custom-select mt-2 mb-4 px-2 h-10 shadow bg-white"
                    value={caseState.escortAd.phones[idx].type}
                    onChange={event => {
                      setPhoneNumber(idx, 'type', event.target.value);
                    }}
                  >
                    <option>Cellular</option>
                    <option>VOIP</option>
                  </select>
                </div>

                {idx !== 0 && !reviewing && (
                  <>
                    <Button
                      className="lg:hidden flex w-72 mb-2 items-center font-semibold focus:outline-none text-pursuit-red"
                      onClick={() => setPhoneIndexToRemove(idx)}
                    >
                      <Icons.FaMinusCircle size={18} className="mr-4" />
                      REMOVE PHONE NUMBER
                    </Button>
                    <button
                      className="hidden lg:block w-10 ml-4 mt-2 focus:outline-none"
                      onClick={() => {
                        setPhoneIndexToRemove(idx);
                      }}
                      type="button"
                    >
                      <img src={TrashIcon} alt="Trash" className="w-5" />
                    </button>
                  </>
                )}
              </div>
            ))
          : null}

        {!reviewing && (
          <Button
            className="flex w-72 mb-8 items-center font-semibold focus:outline-none text-guardian-darkblue"
            onClick={addPhoneNumber}
            justify="justify-start"
          >
            <Icons.FaPlusCircle size={18} className="mr-4" />
            ADD PHONE NUMBER
          </Button>
        )}

        <label className="text-pursuit-gray">Description</label>
        <textarea
          placeholder="Please add any additional context about this source here"
          className="h-24 px-2 mt-2 mb-4 shadow"
          value={caseState.escortAd.description || ''}
          onChange={event => {
            setInput('description', event.target.value);
          }}
        />

        {saveAndAddButton}

        <Feedback comments={caseState?.feedbackComments || []} stepId={ESCORT_AD} status={caseState?.status} />
      </div>
    </>
  );
};

export default EscortAdForm;
