import React from 'react';

import { listVolunteers } from '../../../generated/graphql/queries';
import { UserGrid, StatusPill } from '../../../components';
import { VolunteerStatus, UserTypes } from '../../../constants';

const volunteerStatusOptions = [
  { value: VolunteerStatus.Certified, label: 'Certified' },
  { value: VolunteerStatus.Pending, label: 'Pending' },
  { value: VolunteerStatus.Uncertfied, label: 'Uncertified' },
  { value: VolunteerStatus.Disabled, label: 'Disabled' },
];

const Volunteers = () => {
  const renderUserStatus = ({ userStatus: status }) => <StatusPill status={status} />;

  const statusColumn = { title: 'Status', fieldName: 'userStatus', renderFunction: renderUserStatus, sortable: true };

  return (
    <UserGrid
      query={listVolunteers}
      queryName="listVolunteers"
      statusOptions={volunteerStatusOptions}
      title="Volunteer Users"
      initialStatus={VolunteerStatus.Pending}
      statusColumn={statusColumn}
      statusFilterLabel="User Status"
      createUserDefaultType={UserTypes.Volunteer}
    />
  );
};

export default Volunteers;
