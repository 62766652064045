import React, { useState, useEffect, useContext } from 'react';
import ReactGA from 'react-ga4';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import HeaderText from '../../../components/HeaderText';
import Button from '../../../components/Button';
import TextField from '../../../components/TextField';
import Container from '../../../components/Container';
import { useUser } from '../../../contexts/userContext';
import { NotificationContext } from '../../../helpers/AlertContext/AlertContext.js';
import RequireNewPassword from '../RequireNewPassword';
import ConfirmAccount from '../ConfirmAccount';

const Login = () => {
  const history = useHistory();
  const { state: { email: confirmationEmail, password: confirmationPassword, origin } = {} } = useLocation();
  const [email, setEmail] = useState(confirmationEmail || '');
  const [password, setPassword] = useState(confirmationPassword || '');

  const { loadUser } = useUser();
  const [, setAlert] = useContext(NotificationContext);

  const currPageState = origin === 'createAccount' ? 'confirmation' : 'login';
  const [pageState, setPageState] = useState(currPageState);
  const [prevPageState, setPrevPageState] = useState('');
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    if (prevPageState !== pageState) {
      if (pageState === 'newPass') {
        ReactGA.send({ hitType: 'pageview', page: '/reset-password' });
      } else if (pageState === 'confirmation') {
        ReactGA.send({ hitType: 'pageview', page: '/create-account_confirm' });
      } else {
        ReactGA.send({ hitType: 'pageview', page: '/login' });
      }
      setPrevPageState(pageState);
    }
  }, [pageState, prevPageState]);

  const signIn = async () => {
    try {
      const authenticatedUser = await Auth.signIn({
        username: email.toLowerCase(),
        password,
      });

      setAuthUser(authenticatedUser);
      if (authenticatedUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setPageState('newPass');
      } else {
        await loadUser();
        history.push('/');
      }

      setEmail('');
      setPassword('');
    } catch (error) {
      if (error && error.code === 'UserNotConfirmedException') {
        setPageState('confirmation');
      } else {
        setAlert({
          type: 'SET_NOTIFICATION',
          payload: {
            occurs: true,
            message: error.message,
            textColor: 'redText',
            borderColor: 'redBorder',
          },
        });
      }
    }
  };

  switch (pageState) {
    case 'newPass':
      return <RequireNewPassword authUser={authUser} />;
    case 'confirmation':
      return <ConfirmAccount authData={{ email, password }} />;
    default:
      return (
        <Container
          grayedBackground
          height="lg:h-168"
          width="lg:w-160"
          padding="p-4 md:px-10 md:py-8 lg:px-20"
          className="flex flex-col"
          margin="mx-3 mt-3 md:mx-12 lg:mx-auto lg:m-auto"
        >
          <center>
            <HeaderText noBold fontSize="text-3xl lg:text-4xl">
              Log In
            </HeaderText>
          </center>

          <div className="flex flex-col">
            <TextField
              type="text"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeHolder="Email"
              label="Email"
              className="mb-4"
            />
            <TextField
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeHolder="Password"
              label="Password"
              className="mb-4"
            />
            <Button solidBlue onClick={signIn}>
              SUBMIT
            </Button>
            <div className="flex justify-between mt-4">
              <Button
                anchorTag
                onClick={() => {
                  history.push('create-account');
                }}
              >
                Create Account
              </Button>
              <Button
                anchorTag
                onClick={() => {
                  history.push('forgot-password');
                }}
              >
                Forgot Password
              </Button>
            </div>
          </div>
        </Container>
      );
  }
};

export default Login;
