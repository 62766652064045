import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Container, DataGrid, HeaderText, MessageGrid, Button } from '../../../components';
import { useUser } from '../../../contexts/userContext';
import * as Icons from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useMessages } from '../../../helpers/useMessages';
import { useQueryState } from '../../../helpers/useQueryState';
import { usePrevious } from '../../../helpers/usePrevious';

const USE_VOLUNTEER_VIEW = 'USE_VOLUNTEER_VIEW';

const MessageIndex = () => {
  const pageTitle = 'Messaging';
  const { isAdmin, isEmployee } = useUser();
  const [loadingMore, setLoadingMore] = useState(false);
  const history = useHistory();
  // const [isLoading, setIsLoading] = useState(false);
  // const [messages, setMessages] = useState([]);
  // const [tabName, setTabName] = useState('mine');
  const [tabName, setTabName] = useQueryState('tab', 'mine');
  const prevTabName = usePrevious(tabName);
  // const [sortDirection, setSortDirection] = useState('desc');
  const checkForVolunteerView = () => {
    const useVolunteerSavedPref = localStorage.getItem(USE_VOLUNTEER_VIEW);

    if (useVolunteerSavedPref && useVolunteerSavedPref.toLowerCase() === 'true') {
      return true;
    }

    return false;
  };

  const [useVolunteerView, setUseVolunteerView] = useState(checkForVolunteerView());

  const useAdminView = isAdmin && !useVolunteerView;
  const {
    listMessages,
    listSharedMessages,
    listSentMessages,
    loading,
    sortableMessageFields,
    setSort,
    sortField,
    sortDirection,
    messages,
    nextToken,
    setMessages,
    resetNextToken,
  } = useMessages({ limit: 1000, asAdmin: useAdminView });

  const [hiddenFields, setHiddenFields] = useState([]);

  // useEffect(() => {
  //   console.log({ tabName, prevTabName });
  //   if (prevTabName !== tabName) {
  //     setMessages([]);
  //   }
  // }, [tabName, prevTabName, setMessages]);

  const updateMessages = useCallback(
    async (currentTabName, reset = false) => {
      if (messages.length && !reset) {
        setLoadingMore(true);
      }
      let data;
      switch (currentTabName) {
        case 'shared':
          setHiddenFields([]);
          resetNextToken();
          data = await listSharedMessages({
            group: 'admin',
          });
          break;
        case 'sent':
          setHiddenFields(['senderAlias', 'assignedTo']);
          resetNextToken();
          const group = useAdminView ? 'admin' : undefined;
          data = await listSentMessages({ group });
          break;
        default:
          setHiddenFields(['recipientAlias', 'assignedTo']);
          resetNextToken();
          data = await listMessages();
          break;
      }
      // console.log({ data });
      const updatedMessages = [...(reset ? [] : messages), ...(data || [])];
      // const updatedMessages = [...messages, ...(data || [])];
      // console.log({ currentTabName, reset, sortField, sortDirection, updatedMessages });
      setMessages(updatedMessages);
      setLoadingMore(false);
    },
    // [sortField, sortDirection, setMessages, tabName, messages, listMessages, nextToken]
    [sortField, sortDirection, setMessages, listMessages, nextToken]
  );

  useEffect(() => {
    // const update = async () => {
    //   let data;
    //   switch (tabName) {
    //     case 'shared':
    //       setHiddenFields([]);
    //       data = await listSharedMessages({
    //         group: 'admin',
    //       });
    //       break;
    //     case 'sent':
    //       // setHiddenFields(['recipientAlias']);
    //       setHiddenFields(['senderAlias', 'assignedTo']);
    //       data = await listSentMessages();
    //       break;
    //     default:
    //       setHiddenFields(['recipientAlias', 'assignedTo']);
    //       data = await listMessages();
    //       break;
    //   }
    //   // console.log({ data });
    //   console.log({ tabName, sortField, sortDirection });
    //   setMessages(data);
    // };
    // update();
    // }, [sortField, sortDirection, listMessages, setMessages, tabName]);
    updateMessages(tabName, prevTabName !== tabName);
  }, [sortField, sortDirection, setMessages, tabName]);

  const primaryAction = (
    <Button
      linedBlue
      className="flex flex-row px-4 w-full lg:w-40 uppercase"
      onClick={() => {
        history.push('/messaging/create');
      }}>
      <Icons.FaPlusCircle className="mr-2" /> New Message
    </Button>
  );
  const activateTab = tabButton => (
    <div className="relative flex flex-col">
      {tabButton}
      <div className="absolute inset-y-0 w-full border-b-4 border-pursuit-red" />
    </div>
  );

  let myTab = (
    <Button
      tab
      onClick={() => {
        // setMessages([]);
        resetNextToken();
        return setTabName('mine');
      }}
      // disabled={tabName === 'mine'}
      className="px-0 text-lg border-b-4 border-transparent rounded-none text-pursuit-gray focus:outline-none hover:border-pursuit-red">
      Mine
    </Button>
  );
  let sentTab = (
    <Button
      tab
      onClick={() => {
        // setMessages([]);
        resetNextToken();
        return setTabName('sent');
      }}
      // disabled={tabName === 'mine'}
      className="px-0 text-lg border-b-4 border-transparent rounded-none text-pursuit-gray focus:outline-none hover:border-pursuit-red">
      Sent
    </Button>
  );
  let sharedTab = useAdminView && (
    <Button
      tab
      onClick={() => {
        // setMessages([]);
        resetNextToken();
        return setTabName('shared');
      }}
      // disabled={tabName === 'all'}
      className="px-0 text-lg border-b-4 border-transparent rounded-none text-pursuit-gray focus:outline-none hover:border-pursuit-red">
      Shared
    </Button>
  );
  switch (tabName) {
    case 'mine':
      myTab = activateTab(myTab);
      break;
    case 'shared':
      sharedTab = activateTab(sharedTab);
      break;
    case 'sent':
      sentTab = activateTab(sentTab);
      break;
    default:
      break;
  }

  const tabs = useAdminView && (
    <div className="bottom-0 right-0 flex-row hidden pr-4 mt-4 mb-2 lg: lg:flex">
      <span className="ml-6">{myTab}</span>
      <span className="ml-6">{sentTab}</span>
      <span className="ml-6">{sharedTab}</span>
    </div>
  );

  const headerSection = (
    <>
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <HeaderText className="mb-4 text-2xl text-left lg:text-4xl">{pageTitle}</HeaderText>
        <div>
          {primaryAction}
          {tabs}
        </div>
      </div>
    </>
  );
  const rowLink = useCallback(
    item => {
      switch (tabName) {
        case 'sent':
          return `/messaging/view/${item.createRequestId}`;
        default:
          return `/messaging/read/${item.id}`;
      }
    },
    [tabName]
  );

  return (
    <Container height="lg:h-152" padding="p-4" margin="m-3 lg:m-auto lg:mx-8" className="relative">
      {headerSection}
      <MessageGrid
        messages={messages}
        isLoading={!loadingMore && loading}
        sortableFields={sortableMessageFields}
        advancedView={useAdminView}
        hasMore={!!nextToken}
        // loadNextPage={updateMessages}
        // sort={sort}
        rowLink={rowLink}
        sort={{
          fieldName: sortField,
          direction: sortDirection,
        }}
        setSort={sortParams => {
          setMessages([]);
          console.log({ sortParams });
          setSort(sortParams);
        }}
        hiddenFields={hiddenFields}
      />
      {/* {JSON.stringify({ messages })} */}
    </Container>
  );
};

export default MessageIndex;
