import { Link } from 'react-router-dom';
import React, { useRef } from 'react';
import * as Icon from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from '../Spinner';

const DataGrid = ({
  className,
  columns,
  data,
  rowLink,
  loading,
  sort,
  setSort,
  loadNextPage,
  hasMore,
  noRecordsText,
  containerHeight,
  gridKey,
}) => {
  const scrollContainer = useRef();

  const gridTemplateColumns = columns.map(x => `minmax(0, ${x.width || '1fr'})`).join(' ');

  const renderColumnTitle = col => {
    if (!col.sortable) {
      return col.title;
    }

    const isSortedAsc = sort && sort.fieldName && sort.fieldName === col.fieldName && sort.direction === 'ASC';
    const isSortedDesc = sort && sort.fieldName && sort.fieldName === col.fieldName && sort.direction === 'DESC';

    return (
      <div className="flex items-center ">
        <button type="button" onClick={() => applySort(col)} className="flex focus:outline-none">
          <div className="flex flex-col justify-center mr-1">
            <Icon.FaChevronUp
              size={12}
              style={{ marginBottom: -1 }}
              className={`${isSortedAsc ? 'text-pursuit-blue' : 'text-gray-200'}`}
            />

            <Icon.FaChevronDown
              size={12}
              style={{ marginTop: -1 }}
              className={`${isSortedDesc ? 'text-guardian-blue' : 'text-gray-200'}`}
            />
          </div>

          {col.title}
        </button>
      </div>
    );
  };

  const applySort = col => {
    if (sort && sort.fieldName && sort.fieldName === col.fieldName) {
      if (sort.direction === 'ASC') {
        setSort({ fieldName: col.fieldName, direction: 'DESC' });

        return;
      }
    }

    setSort({ fieldName: col.fieldName, direction: 'ASC' });
  };

  const scrollableTarget = `scrollableDiv-${gridKey}`;
  const gridClass = `lg:grid ${className} px-2 lg:px-0`;

  return (
    <>
      <div
        className={`${gridClass} ${
          scrollContainer &&
          scrollContainer.current &&
          scrollContainer.current.scrollHeight > scrollContainer.current.clientHeight
            ? 'mr-4'
            : ''
        }`}
        style={{ gridTemplateColumns }}>
        {columns.map((col, i) => (
          <div
            className={`hidden lg:flex items-center text-left text-pursuit-gray border-b border-pursuit-gray px-1 ${col.width ||
              ''}`}
            key={`col-${i}`}>
            {renderColumnTitle(col)}
          </div>
        ))}
      </div>

      <div className={`${containerHeight} overflow-y-auto`} ref={scrollContainer} id={scrollableTarget}>
        <InfiniteScroll
          dataLength={(data && data.length) || 0} // This is important field to render the next data
          next={loadNextPage}
          hasMore={hasMore}
          scrollableTarget={scrollableTarget}>
          <div className={gridClass} style={{ gridTemplateColumns }}>
            {!loading &&
              data.map((record, i) =>
                columns.map((col, j) => {
                  let value = '-';

                  if (typeof col.value === 'function') {
                    value = col.value(record);
                  } else if (col.value) {
                    value = col.value;
                  } else if (col.fieldName) {
                    value = record[col.fieldName] || '-';
                  }

                  const rowClass = record.className;

                  if (rowLink && !col.noLink) {
                    let path = '';

                    if (typeof rowLink === 'function') {
                      path = rowLink(record);
                    } else {
                      path = rowLink;
                    }

                    return (
                      <Link
                        to={path}
                        className={`flex items-center py-1 lg:py-2 border-b lg:border-gray-300 lg:px-1 text-pursuit-gray font-light 
                  lg:${col.width} 
                  ${rowClass ? ' ' + rowClass : ''}
                  ${j === columns.length - 1 ? 'pb-4 lg:pb-2 border-gray-300' : 'border-transparent'}
                  ${j === 0 && 'pt-4 lg:pt-2'}`}
                        key={`cell-${record.id || i}-${j}`}
                        // style={{ gridArea: 'main' }}
                      >
                        <div className="flex flex-row items-center justify-between w-full">
                          {col.title && <span className="lg:hidden font-semibold">{col.title}:</span>}
                          {col.renderFunction ? col.renderFunction(record) : value}
                        </div>

                        {j === columns.length - 1 && rowLink && (
                          <div className="hidden flex-1 lg:flex justify-end">
                            <Icon.FaChevronRight className="ml-2 text-gray-400" />
                          </div>
                        )}
                      </Link>
                    );
                  }

                  return (
                    <div
                      className={`flex items-center py-1 lg:py-2 border-b lg:border-gray-300 lg:px-1 text-pursuit-gray font-light 
              lg:${col.width} 
              ${j === columns.length - 1 ? 'pb-4 lg:pb-2 border-gray-300' : 'border-transparent'}
              ${j === 0 && 'pt-4 lg:pt-2'}`}
                      key={`cell-${record.id}-${j}`}
                      // style={{ gridArea: 'main' }}
                    >
                      <div className="flex flex-row items-center justify-between w-full">
                        {col.title && <span className="lg:hidden font-semibold">{col.title}:</span>}
                        {col.renderFunction ? col.renderFunction(record) : value}
                      </div>
                    </div>
                  );
                })
              )}
          </div>
          {loading && (
            <div className="w-full flex justify-center py-10">
              <Spinner />
            </div>
          )}
          {data.length === 0 && !loading && (
            <div className="w-full text-center">
              <p className="my-10">{`${noRecordsText}` || 'No Records'}</p>
            </div>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default DataGrid;
