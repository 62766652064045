import React, { useEffect, useState, useContext } from 'react';
import { DateTime } from 'luxon';
import { API, graphqlOperation } from 'aws-amplify';
import { snakeCase } from 'change-case';
import * as Icons from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

import { NotificationContext } from '../../helpers/AlertContext/AlertContext';

import Container from '../Container';
import HeaderText from '../HeaderText';
import DataGrid from '../DataGrid';
import Button from '../Button';
import DropDown from '../DropDown';
import ToolTip from '../ToolTip';
import { useUser } from '../../contexts/userContext';

const Volunteers = ({
  statusOptions,
  title,
  initialStatus,
  query,
  queryName,
  statusColumn,
  statusFilterLabel,
  createUserDefaultType,
}) => {
  const { isAdmin } = useUser();

  const [, setAlert] = useContext(NotificationContext);

  const history = useHistory();

  const [nextUsersToken, setNextUsersToken] = useState();

  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);

  const [userStatus, setUserStatus] = useState(initialStatus);

  const [filters, setFilters] = useState({
    userStatus: initialStatus,
    searchTerm: '',
  });

  const [sort, setSort] = useState({
    fieldName: 'createdAt',
    direction: 'DESC',
  });

  const [filtersOnLastSearch, setFiltersOnLastSearch] = useState(null);
  const [sortOnLastSearch, setSortOnLastSearch] = useState({
    fieldName: 'createdAt',
    direction: 'DESC',
  });

  const [search, setSearch] = useState('');

  useEffect(() => {
    fetchUsers();
  }, [sort]);

  useEffect(() => {
    fetchUsers();
  }, [filters]);

  const fetchUsers = async () => {
    setUsersLoading(true);

    try {
      let nextTokenToUse = nextUsersToken;

      let oldUsers = nextTokenToUse ? [...users] : [];

      if (
        !filtersOnLastSearch ||
        filtersOnLastSearch.userStatus !== filters.userStatus ||
        filtersOnLastSearch.searchTerm !== filters.searchTerm ||
        sortOnLastSearch.fieldName !== sort.fieldName ||
        sortOnLastSearch.direction !== sort.direction
      ) {
        setNextUsersToken(null);

        nextTokenToUse = null;
        oldUsers = [];
      }

      setSortOnLastSearch(sort);
      setFiltersOnLastSearch(filters);

      const params = {
        userStatus: filters.userStatus,
        searchTerm: filters.searchTerm,
        nextToken: nextTokenToUse,
        sortField: sort.fieldName ? snakeCase(sort.fieldName).toUpperCase() : '',
        sortOrder: sort.direction,
        limit: 1000,
      };

      const response = await API.graphql(graphqlOperation(query, params));

      const result = response.data[queryName];

      setNextUsersToken(result.nextToken);

      setUsers([...oldUsers, ...result.items]);
    } catch (error) {
      console.error('Error Loading users: ', error);

      setAlert({
        type: 'SET_NOTIFICATION',
        payload: {
          occurs: true,
          message: 'Error loading users',
          textColor: 'redText',
          borderColor: 'redBorder',
        },
      });
    }

    setUsersLoading(false);
  };

  const getCreatedDate = item => (item.createdAt ? DateTime.fromISO(item.createdAt).toLocaleString() : '-');
  const getLastSignInDate = item => (item.lastSignInAt ? DateTime.fromISO(item.lastSignInAt).toLocaleString() : '-');

  return (
    <Container height="lg:h-152" padding="p-4" margin="m-3 lg:m-auto lg:mx-8" className="relative">
      <div className="flex flex-col lg:px-4 pt-4">
        <div className="flex flex-col lg:flex-row mb-4 lg:mb-0 items-start lg:items-center justify-between">
          <HeaderText className="text-2xl text-left mb-4 lg:text-4xl">{title}</HeaderText>

          {isAdmin && (
            <Button
              linedBlue
              className="flex flex-row px-4 w-full lg:w-40"
              onClick={() => {
                history.push('/admin/create-user', { userType: createUserDefaultType });
              }}
            >
              <Icons.FaPlusCircle className="mr-2" /> NEW USER
            </Button>
          )}
        </div>

        <div className="lg:flex items-end justify-end mb-10">
          <div className="lg:mr-4">
            <DropDown
              value={statusOptions.find(x => x.value === userStatus)}
              onChange={option => {
                setUserStatus(option ? option.value : null);
              }}
              containerClassName="mb-4 lg:mb-0"
              width="w-full lg:w-48 xl:w-64"
              label={statusFilterLabel}
              options={statusOptions}
              placeholder={statusFilterLabel}
              isClearable
            />
          </div>

          <div className="flex flex-col">
            <div className="flex flex-row">
              <p className="light-primary-blue-text font-light mb-1">Search Term</p>

              <ToolTip
                tooltipText="Find records with the search term included in the First Name, Last Name, Alias, Email, State, or ZIP fields."
                title="Search Term"
                className="text-pursuit-gray ml-2"
              />
            </div>

            <input
              type="text"
              value={search}
              onChange={event => setSearch(event.target.value)}
              placeholder="Search Term"
              className="bg-gray-100 h-10 mb-4 mt-2 lg:mb-0 lg:mt-0 lg:mr-4 px-4 w-full lg:w-48 xl:w-64"
              onKeyDown={event => {
                const pressedKey = event.key;

                if (pressedKey === 'Enter') {
                  setFilters({
                    userStatus,
                    searchTerm: search,
                  });
                }
              }}
            />
          </div>

          <Button
            solidBlue
            className="px-4 w-full lg:w-24"
            onClick={() => {
              setFilters({
                userStatus,
                searchTerm: search,
              });
            }}
          >
            FILTER
          </Button>
        </div>

        <DataGrid
          columns={[
            { title: 'First Name', fieldName: 'firstName', sortable: true },
            { title: 'Last Name', fieldName: 'lastName', sortable: true },
            { title: 'Alias', fieldName: 'alias', sortable: true },
            { title: 'Email', fieldName: 'email', sortable: true, width: '2fr' },
            { title: 'Created', fieldName: 'createdAt', value: getCreatedDate, sortable: true },
            { title: 'Last Sign In', fieldName: 'lastSignInAt', value: getLastSignInDate, sortable: true },
            { title: 'State', fieldName: 'state', sortable: true },
            { title: 'ZIP', fieldName: 'zip', sortable: false },
            statusColumn,
          ]}
          sort={sort}
          setSort={setSort}
          loading={usersLoading}
          data={users}
          noRecordsText="No users found with the selected filters and sort options"
          rowLink={item => `/user/${item.id}`}
          loadNextPage={() => fetchUsers()}
          hasMore={!!nextUsersToken}
          containerHeight="h-88"
          gridKey="employee-cases"
        />
      </div>
    </Container>
  );
};

export default Volunteers;
