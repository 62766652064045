import React, { useEffect, useState, useCallback } from 'react';
import { DataGrid, DateDisplay, MessageTo } from '../';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../contexts/userContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { isMessageFromAdmin } from '../../helpers/useMessages';
import { API, graphqlOperation } from 'aws-amplify';
import { listEmployees } from '../../generated/graphql/queries';
import { useMessages } from '../../helpers/useMessages';
import { MessageToSummary } from '../MessageTo';
// import { MessageTo } from '../';

const USE_VOLUNTEER_VIEW = 'USE_VOLUNTEER_VIEW';

const MessageGrid = ({
  messages = [],
  defaultSender = 'Project 1591',
  isLoading = false,
  advancedView = false,
  hasMore = false,
  loadNextPage = () => {},
  rowLink = item => `/messaging/read/${item.id}`,
  sortableFields = [],
  hiddenFields = [],
  sort,
  setSort,
}) => {
  // const [isLoading, setIsLoading] = useState(defaultIsLoading);
  const { assignMessage, setMessages } = useMessages({ limit: 100 });
  const history = useHistory();
  const checkForVolunteerView = () => {
    const useVolunteerSavedPref = localStorage.getItem(USE_VOLUNTEER_VIEW);

    if (useVolunteerSavedPref && useVolunteerSavedPref.toLowerCase() === 'true') {
      return true;
    }

    return false;
  };

  const [useVolunteerView, setUseVolunteerView] = useState(checkForVolunteerView());
  const { isEmployee, isAdmin, user } = useUser();
  const useAdminView = isAdmin && !useVolunteerView;

  const [employeeOptions, setEmployeeOptions] = useState([]);
  const fetchEmployees = async () => {
    try {
      let nextEmployeeToken = null;
      let employeeList = [];

      do {
        const params = {
          nextToken: nextEmployeeToken,
          limit: 100,
          sortOrder: 'ASC',
        };

        const result = await API.graphql(graphqlOperation(listEmployees, params));

        nextEmployeeToken = result.data.listEmployees.nextToken;

        employeeList = [...employeeList, ...result.data.listEmployees.items];
      } while (nextEmployeeToken);

      const options = employeeList.map(x => ({
        value: x.id,
        label: `${x.firstName} ${x.lastName}`,
      }));

      setEmployeeOptions(options);

      return options;
    } catch (error) {
      console.error('Error Loading employees: ', error);

      // setAlert({
      //   type: 'SET_NOTIFICATION',
      //   payload: {
      //     occurs: true,
      //     message: 'Error loading employees',
      //     textColor: 'redText',
      //     borderColor: 'redBorder',
      //   },
      // });
    }
  };

  useEffect(() => {
    if (useAdminView) {
      fetchEmployees();
    }
  }, [useAdminView]);

  const formatAssignedTo = item => {
    // if (item.assignedTo) {
    return (
      <select
        value={item.assignedTo || ''}
        className={`custom-select pl-2 h-10 w-48 lg:mr-4 border border-gray-200 bg-white rounded ${
          !item.assignedTo ? 'text-gray-200' : 'text-pursuit-gray'
        }`}
        // onChange={() => {}}
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();
        }}
        onChange={event => {
          const employee = employeeOptions.find(x => x.value === event.target.value);
          const name = employee ? employee.label : null;
          const userId = event.target.value || null;
          const oldMessages = [...messages];
          const messageToUpdateIdx = oldMessages.findIndex(x => x.id === item.id);
          if (messageToUpdateIdx !== -1) {
            const updatedMessage = oldMessages[messageToUpdateIdx];
            updatedMessage.assignedTo = userId;
            updatedMessage.assignedToName = name;
            if (userId) {
              delete updatedMessage.readAt;
            }
            // console.log({ userId, name, updatedMessage });
            oldMessages[messageToUpdateIdx] = updatedMessage;
            setMessages([...oldMessages]);
            assignMessage({ id: item.id, userId, name });
          }
        }}>
        <option key="default-state" value="">
          Unassigned
        </option>

        {employeeOptions.map((x, i) => (
          <option value={x.value} key={i}>
            {x.label}
          </option>
        ))}
      </select>
    );
    // }

    // return item.assignedToName || '-';
  };

  const formatFrom = message => {
    // let aliasMain = true;

    if (!message) {
      return;
    }

    const { sender, senderAlias, senderName, id } = message;
    // const from = isEmployee && !useVolunteerView && senderAlias;
    if (useAdminView && isMessageFromAdmin(message)) {
      const senderTitle = `Alias: ${senderAlias}`;
      return (
        <span title={senderTitle}>
          {/* {isEmployee && !useVolunteerView && (
            <FontAwesomeIcon icon={icon({ name: 'user-secret', style: 'duotone' })} />
          )}{' '} */}
          {senderName || defaultSender}
        </span>
      );
    } else if (useAdminView) {
      const senderTitle = `Real name: ${senderName}`;
      return (
        <span title={senderTitle}>
          {isEmployee && !useVolunteerView && (
            <FontAwesomeIcon icon={icon({ name: 'user-secret', style: 'duotone' })} />
          )}{' '}
          {senderAlias || defaultSender}
        </span>
      );
    } else {
      return (
        <span>
          {/* {isEmployee && !useVolunteerView && (
            <FontAwesomeIcon icon={icon({ name: 'user-secret', style: 'duotone' })} />
          )}{' '} */}
          {defaultSender}
        </span>
      );
    }
  };

  const formatTo = message => {
    return <MessageToSummary message={message} options={{ useAdminView }} />;
    // if (!message) {
    //   return;
    // }
    // let toIcon = null;
    // // let title = null;
    // const {
    //   recipientAlias,
    //   recipientName,
    //   id,
    //   recipientGroups,
    //   recipientMeta,
    //   recipientCount,
    //   createRequestId,
    // } = message;
    // let title = `Alias: ${recipientAlias || '-'}`;
    // let to = recipientAlias;
    // if (useAdminView && !(recipientGroups || []).includes('Admin')) {
    //   toIcon = <FontAwesomeIcon icon={icon({ name: 'user-secret', style: 'duotone' })} />;
    //   title = `Real name: ${recipientName || '-'}`;
    // } else if (useAdminView && (recipientGroups || []).includes('Admin')) {
    //   to = recipientName;
    // }
    // if (recipientCount && recipientCount > 1) {
    //   to = `${recipientCount} recipients`;
    //   let details = `${recipientCount} recipients`;
    //   const tipId = `${createRequestId}-data`;
    //   toIcon = <FontAwesomeIcon icon={icon({ name: 'users', style: 'solid' })} />;
    //   const maxSummaryRecipients = 5;
    //   if (recipientMeta && Array.isArray(recipientMeta.users)) {
    //     const recipientList = recipientMeta.users
    //       .slice(0, maxSummaryRecipients)
    //       .map(x => x.name)
    //       .join(', ');
    //     details = `Recipients: ${recipientList}${
    //       recipientCount > maxSummaryRecipients ? `and ${recipientCount - maxSummaryRecipients} more` : ''
    //     }`;
    //   }
    //   return (
    //     <span className="relative">
    //       <span aria-describedby={tipId}>
    //         {toIcon} {to || '-'}
    //       </span>
    //       <span role="tooltip" className="p-4 rounded-md bg-gray-100 text-sm text-gray-400 w-64" id={tipId}>
    //         {details}
    //       </span>
    //     </span>
    //   );
    // }
    // return (
    //   <span title={title}>
    //     {toIcon} {to || '-'}
    //   </span>
    // );
  };

  const formatSeen = message => {
    if (!message) {
      return;
    }
    const { seenAt } = message;
    return seenAt ? (
      <>
        <span
          title={'Seen: ' + DateTime.fromISO(seenAt).toLocaleString(DateTime.DATETIME_FULL)}
          className="text-opacity-50 text-pursuit-gray">
          <FontAwesomeIcon icon={icon({ name: 'eye', style: 'regular' })} />
        </span>
      </>
    ) : (
      <>
        <FontAwesomeIcon icon={icon({ name: 'eye-slash', style: 'regular' })} />
      </>
    );
  };

  const formatFlagged = message => {
    if (!message) {
      return;
    }
    const { adminFlaggedAt, assignedTo } = message;
    let flags = [];
    // return adminFlaggedAt ? (
    //   <>
    //     <span
    //       title={'Flagged: ' + DateTime.fromISO(adminFlaggedAt).toLocaleString(DateTime.DATETIME_FULL)}
    //       className="text-opacity-50 text-pursuit-gray">
    //       <FontAwesomeIcon icon={icon({ name: 'flag-pennant', style: 'solid' })} className="text-pursuit-red" />
    //     </span>
    //   </>
    // ) : (
    //   <>{/* <FontAwesomeIcon icon={icon({ name: 'eye-slash', style: 'regular' })} /> */}</>
    // );
    if (adminFlaggedAt) {
      flags.push(
        <span
          title={'Flagged: ' + DateTime.fromISO(adminFlaggedAt).toLocaleString(DateTime.DATETIME_FULL)}
          className="text-opacity-50 text-pursuit-gray"
          key="flagged">
          <FontAwesomeIcon icon={icon({ name: 'flag-pennant', style: 'solid' })} className="text-pursuit-red" />
        </span>
      );
    }
    if (user && user.id == assignedTo) {
      flags.push(
        <span title={'Assigned to me'} className="text-opacity-50 text-pursuit-gray" key="assigned">
          <FontAwesomeIcon icon={icon({ name: 'user-check', style: 'solid' })} className="text-pursuit-red" />
        </span>
      );
    }
    // const content = flags.join('');
    // reduce flags to a single element
    const content = flags.reduce((prev, curr) => [prev, ' ', curr], null);
    return <>{content}</>;
  };

  // const filterSection = <div className="flex flex-col lg:flex-row lg:justify-between">TODO: Filters go here</div>;
  const gridColumns = [
    {
      title: 'Date',
      fieldName: 'createdAt',
      // width: 'w-auto',
      renderFunction: item => (item.createdAt ? <DateDisplay date={item.createdAt} /> : '-'),
      sortable: sortableFields.includes('createdAt'),
    },
    {
      title: 'From',
      // fieldName: 'sender',
      fieldName: 'senderAlias',
      renderFunction: formatFrom,
      sortable: sortableFields.includes('senderAlias'),
    },
    {
      title: 'Title',
      fieldName: 'title',
      sortable: sortableFields.includes('title'),
      renderFunction: item => {
        // Return custom title or default
        return item.attributes && item.attributes.customTitle ? item.attributes.customTitle || item.title : item.title;
      },
    },
    {
      title: 'Subject',
      fieldName: 'subject',
      renderFunction: item => {
        const moreChevron = item.replyTo ? (
          <FontAwesomeIcon className="pr-2" icon={icon({ name: 'chevron-right', style: 'solid' })} />
        ) : (
          ''
        );
        return (
          <div className="justify-start">
            {moreChevron}
            {item.subject}
          </div>
        );
        // return (item.subject ? item.subject : '-');
      },
      sortable: sortableFields.includes('subject'),
    },
  ].filter(column => !hiddenFields.includes(column.fieldName));

  const gridMessages = (messages || []).map(message => {
    const classes = [];
    if (!message.readAt && message.recipientCount <= 1) {
      classes.push('font-bold');
    }

    if (advancedView && message.adminFlaggedAt) {
      // classes.push('bg-pursuit-red');
      // classes.push('bg-red-100');
    }
    return {
      ...message,
      className: classes.join(' '),
    };
  });

  if (advancedView) {
    gridColumns.splice(
      0,
      0,
      {
        // title: 'Read',
        fieldName: 'seenAt',
        width: '2em',
        renderFunction: formatSeen,
        sortable: sortableFields.includes('seenAt'),
      },
      {
        width: '2em',
        fieldName: 'adminFlaggedAt',
        // sortable: sortableFields.includes('recipientAlias'),
        renderFunction: formatFlagged,
      }
    );
    if (!hiddenFields.includes('recipientAlias')) {
      gridColumns.splice(3, 0, {
        title: 'To',
        fieldName: 'recipientAlias',
        sortable: sortableFields.includes('recipientAlias'),
        renderFunction: formatTo,
      });
    }

    if (!hiddenFields.includes('assignedTo')) {
      gridColumns.splice(7, 0, {
        title: 'Assigned',
        fieldName: 'assignedTo',
        sortable: sortableFields.includes('assignedTo'),
        renderFunction: formatAssignedTo,
        width: '14rem',
        noLink: true,
      });
    }
  }

  // const rowLink = useCallback(item => {
  //   return `/messaging/read/${item.id}`;
  // }, []);

  return (
    <div className="message-grid">
      {/* {filterSection} */}
      <DataGrid
        columns={gridColumns}
        sort={sort}
        setSort={setSort}
        loading={isLoading}
        data={gridMessages}
        noRecordsText="No messages found"
        // rowLink={item => `/messaging/read/${item.id}`}
        rowLink={rowLink}
        // loadNextPage={() => fetchCases(filters)}
        loadNextPage={loadNextPage}
        hasMore={!!hasMore}
        containerHeight="h-88"
        gridKey="employee-messaging"
      />
      {/* <>{JSON.stringify({ hasMore })}</> */}
    </div>
  );
};

export default MessageGrid;
