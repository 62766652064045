import React from 'react';

import { listEmployees, listLeos } from '../../../generated/graphql/queries';
import { LEOGrid, StatusPill } from '../../../components';
import { EmployeeType, UserTypes } from '../../../constants';

const statusOptions = [
  { value: EmployeeType.Admin, label: 'Admin' },
  { value: EmployeeType.Staff, label: 'Staff' },
];

const LEOUsers = () => {
  const renderStaffType = ({ userStatus }) => <StatusPill status={userStatus} />;

  const statusColumn = { title: 'Type', fieldName: 'userStatus', renderFunction: renderStaffType, sortable: false };

  return (
    <LEOGrid
      query={listLeos}
      queryName="listLeos"
      statusOptions={statusOptions}
      title="LEO Users"
      statusColumn={statusColumn}
      statusFilterLabel="Type"
      createUserDefaultType={UserTypes.Staff}
    />
  );
};

export default LEOUsers;
