import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTitle } from '../../../helpers/useTitle';
import { MessageForm, Container, HeaderText, Button, PopUpConfirmation } from '../../../components';
import { useQueryState } from '../../../helpers/useQueryState';
import { useParams, useLocation } from 'react-router-dom';
import { useUser } from '../../../contexts/userContext';
import { useHistory } from 'react-router-dom';

const USE_VOLUNTEER_VIEW = 'USE_VOLUNTEER_VIEW';
/**
 * Create message page
 * @param {Object} props - React props
 * @param {string} props.pageTitle - The page title
 * @param {function} props.sendCallback - Callback function to run after message is sent
 * @param {function} props.cancelCallback - Callback function to run after message is cancelled
 * @returns {JSX.Element} - The page JSX
 */
const MessageCreate = ({
  pageTitle = 'Create New Message',
  sendCallback,
  cancelUrl: defaultCancelUrl,
  cancelCallback,
  createLabel = 'Send',
  cancelLabel = 'Cancel',
}) => {
  useTitle(pageTitle);
  // Get recipients from query params
  let manualSelectorComponent = null;
  const [recipients] = useQueryState('recipients');
  const { state } = useLocation();
  const history = useHistory();
  const { isAdmin, isEmployee } = useUser();
  const [message] = useQueryState('message', state && state.message);
  const subject = state && state.subject;
  const [title, setTitle] = useQueryState('title', state && state.title);
  const isReply = state && !!state.replyTo;
  const cancelUrl = defaultCancelUrl ? defaultCancelUrl : isReply ? `/messaging/read/${state.replyTo}` : '/messaging';
  const [showSentConfirmation, setShowSentConfirmation] = useState(false);

  // const recipientsArray = recipients ? recipients.split(',').map(recipient => ({ recipient })) : [];
  const [recipientsArray, setRecipientsArray] = useState(
    recipients ? recipients.split(',').map(recipient => ({ recipient })) : []
  );
  const volunteer = true;
  // let noUserSelector = true;
  const [noUserSelector, setNoUserSelector] = useState(true);
  const [notification, setNotification] = useState({});
  // let subjectDisabled = false;
  const [subjectDisabled, setSubjectDisabled] = useState(false);
  // let noTitle = true;
  const [noTitle, setNoTitle] = useState(true);

  const checkForVolunteerView = () => {
    const useVolunteerSavedPref = localStorage.getItem(USE_VOLUNTEER_VIEW);

    if (useVolunteerSavedPref && useVolunteerSavedPref.toLowerCase() === 'true') {
      return true;
    }

    return false;
  };

  const [useVolunteerView, setUseVolunteerView] = useState(checkForVolunteerView());

  useEffect(() => {
    if (!isEmployee || useVolunteerView) {
      // // If recipients is empty set it to a value for admins
      if (recipientsArray.length === 0) {
        setRecipientsArray([{ recipient: 'ADMIN' }]);
        // recipientsArray.push({ recipient: 'ADMIN' });
      }
      if (!title) {
        setTitle('User Inquiry');
      }
      // manualSelectorComponent = (
      //   <div className="mb-4">
      //     <div className="flex">
      //       <p className="light-primary-blue-text font-light mb-1 mr-1">To: </p>
      //       <p className="font-light mb-1 mr-1">Project 1591</p>
      //     </div>
      //   </div>
      // );
    } else {
      // // noUserSelector = false;
      setNoUserSelector(false);
      // // subjectDisabled = false;
      setSubjectDisabled(false);
      // // noTitle = false;
      setNoTitle(false);
    }
  }, [useVolunteerView]);

  // const notification = { recipients: recipientsArray, message, title, subject, replyTo: state && state.replyTo };
  useEffect(() => {
    const n = {
      recipients: recipientsArray,
      message,
      title: title,
      subject,
      replyTo: state && state.replyTo,
    };
    setNotification(n);
    // console.log('notification', JSON.stringify({ n, state }, null, 2));

    // setNotification({ recipients: recipientsArray, message, title, subject, replyTo: state && state.replyTo });
  }, [recipientsArray, message, title, subject, state]);
  return (
    <Container
      height="lg:min-h-104"
      width="lg:w-4/5"
      padding="p-4 md:px-10 md:py-8 lg:px-20"
      className="flex flex-col"
      margin="mx-3 md:mx-12 lg:mx-auto mt-4">
      <HeaderText fontSize="text-4xl" className="mb-12">
        {pageTitle}
      </HeaderText>
      {showSentConfirmation && (
        <PopUpConfirmation
          title="Message Sent"
          content={`Your message has been sent.`}
          noCancel
          // onCancel={toggleDeleteConfirmation}
          onConfirm={() => {
            // Navigate to the messaging page
            history.push('/messaging');
            // setDeleting(true);
            // deleteMessage(message.id, false);
            // setTimeout(() => {
            //   setDeleting(false);
            //   history.push('/messaging');
            // }, 2000);
          }}
          confirmText="OK"
          // confirmLoading={deleting}
          confirmLoadingText="OK"
          className="w-11/12 lg:w-auto"
          destructive
        />
      )}
      <MessageForm
        // notification={{ ...{ recipients: recipientsArray, message, title, subject, replyTo: state && state.replyTo } }}
        notification={notification}
        isReply={isReply}
        createLabel={createLabel}
        cancelLink={cancelUrl}
        cancelCallback={cancelCallback}
        createCallback={() => setShowSentConfirmation(true)}
        noUserSelector={noUserSelector}
        subjectDisabled={subjectDisabled}
        noTitle={noTitle}
        manualSelectorComponent={manualSelectorComponent}
      />
      {/* {JSON.stringify(notification, null, 2)} */}
    </Container>
  );
};
MessageCreate.propTypes = {
  pageTitle: PropTypes.string,
  cancelUrl: PropTypes.string,
  createLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  sendCallback: PropTypes.func,
  cancelCallback: PropTypes.func,
};

export default MessageCreate;
