/**
 * Markdown parser. Returns metadata and content of a markdown string.
 * @module helpers/markdown/parser
 * @param {string} markdown
 * @returns {{metadata: Object, content: string}}
 */
export const parseMarkdownPage = markdown => {
  const metadataRegex = /^---\n([\s\S]*?)\n---\n/;
  // const parts = markdown.match(metadataRegex);
  const metadataMatch = markdown.match(metadataRegex);

  if (!metadataMatch) {
    return { metadata: {}, content: markdown };
  }
  const metadata = metadataMatch[1].split('\n').reduce((acc, line) => {
    const [key, value] = line.split(': ');

    return { ...acc, [key]: value };
  }, {});
  const content = markdown.replace(metadataRegex, '');

  return { metadata, content };
  // const metadata = parts.split('\n').reduce((acc, line) => {
  //   const [key, value] = line.split(': ');

  //   return { ...acc, [key]: value };
  // }, {});
  // const content = markdown.replace(metadataRegex, '');

  // return { metadata, content };

  // const content = split.slice(1).join('\n---\n');

  // return { metadata, content };
};

/**
 * Parse markdown string into html
 * @param {string} markdown
 * @returns {string}
 */
export const parseMarkdown = markdown => {
  const html = (markdown || '')
    // Replace greater and less then signs
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/^### (.*$)/gim, '<h3>$1</h3>') // h3 tag
    .replace(/^## (.*$)/gim, '<h2>$1</h2>') // h2 tag
    .replace(/^# (.*$)/gim, '<h1>$1</h1>') // h1 tag
    .replace(/\*\*(.*)\*\*/gim, '<b>$1</b>') // bold text
    .replace(/\*(.*)\*/gim, '<i>$1</i>') // italic text
    .replace(/!\[(.*?)\]\((.*?)\)/gim, '<img alt="$1" src="$2" />') // images
    .replace(/\[(.*?)\]\((.*?)\)/gim, '<a href="$2">$1</a>') // links
    .replace(/^\> (.*$)/gim, '<blockquote>$1</blockquote>') // blockquotes
    // Wrap all lines that start with a * in a <ul>
    // --- with hr
    .replace(/^---$/gim, '<hr class="border-gray-200" />')

    // .replace(/^\s*\n\*/gim, '<ul class="list-disc list-inside mt-6">\n*') // ul lists
    // .replace(/^(\*.+)\s*\n([^\*])/gim, '$1\n</ul>\n\n$2') // ul lists

    // .replace(/^\* (.*$)/gim, '<li>$1</li>') // ul lists
    // .replace(/^\s*\n\d\./gim, '<ol class="list-decimal list-inside mt-6">\n1.') // ol lists
    // .replace(/^\d\. (.*$)/gim, '<li>$1</li>') // lists
    // .replace(/^(\d\..+)\s*\n([^\d\.])/gim, '$1\n</ol>\n\n$2') // ol lists

    // .replace(/^\* (.*$)/gim, '<li>$1</li>') // lists
    // .replace(/^\d\. (.*$)/gim, '<li>$1</li>') // lists
    .replace(/^(?!<h|<b|<i|<ul|<ol|<li|<img|<a|<br|\n)(.*$)/gim, '<p class="mb-4">$1</p>');
  // .replace(/\n$/gim, '<br />'); // line breaks

  return html.trim(); // using trim method to remove whitespace
};
