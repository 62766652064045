import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import { CreateUser, LEODetails, StaffUsers } from '../pages/admin';
import LEOUsers from '../pages/admin/LEOUsers';
import CreateLEO from '../pages/admin/CreateLEO';

const AdminRouting = () => {
  const location = useLocation();

  const adminPath = '/admin';

  return (
    <Switch location={location} key={location.pathname}>
      <Route path={`${adminPath}/create-user`}>
        <CreateUser />
      </Route>

      <Route path={`${adminPath}/create-leo`}>
        <CreateLEO />
      </Route>



      <Route path={`${adminPath}/staff`}>
        <StaffUsers />
      </Route>
      <Route path={`${adminPath}/leo`}>
        <LEOUsers />
      </Route>
      <Route path={`${adminPath}/leo-user/:userId`}>
        <LEODetails />
      </Route>
    </Switch>
  );
};

export default AdminRouting;
