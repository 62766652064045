/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUserApproved = /* GraphQL */ `
  subscription OnUserApproved($id: String!) {
    onUserApproved(id: $id) {
      id
      identityId
      email
      alias
      firstName
      lastName
      dateOfBirth
      country
      state
      zip
      heardAboutUs
      watchedIntroVideo
      userStatus
      certificatePath
      _deleted
      lastSignInAt
      approvedAt
      disabledAt
      createdAt
      updatedAt
    }
  }
`;
export const onNotificationCreated = /* GraphQL */ `
  subscription OnNotificationCreated($recipient: String!) {
    onNotificationCreated(recipient: $recipient) {
      id
      title
      message
      type
      recipient
      createdAt
      updatedAt
    }
  }
`;
