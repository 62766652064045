import React, { useCallback, useState } from 'react';

import Button from '../Button';
import HeaderText from '../HeaderText';

const PopUpConfirmation = ({
  title,
  content,
  onConfirm,
  onCancel,
  cancelText,
  confirmText,
  cancelLoadingText,
  confirmLoadingText,
  noCancel,
  noConfirm,
  confirmLoading,
  cancelLoading,
  destructive,
  forceScrollBottom,
  className,
  contentWrapper,
}) => {
  const [reachedBottom, setReachedBottom] = useState(false);

  const handleScroll = useCallback(
    event => {
      if (!reachedBottom) {
        const node = event.target;
        const bottom = node.scrollHeight - node.scrollTop < node.clientHeight * 1.25;

        if (bottom) {
          setReachedBottom(true);
        }
      }
    },
    [reachedBottom]
  );

  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0 h-screen"
      style={{ backgroundColor: 'rgb(100, 100, 100, 0.75)', zIndex: 40 }}
    >
      <div
        className={`absolute flex flex-col bg-white py-6 px-8 shadow-xl ${className}`}
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <HeaderText noBold fontSize="text-2xl lg:text-3xl mb-2">
          {title}
        </HeaderText>

        <div onScroll={handleScroll} className={`${contentWrapper}`}>
          {content}
        </div>

        <div className="flex justify-end mt-8">
          {!noCancel ? (
            <Button
              linedBlue
              noPadding
              className="px-3 mr-6 min-w-20 lg:min-w-24"
              onClick={onCancel}
              loading={cancelLoading}
            >
              {cancelLoading ? cancelLoadingText || 'CANCELING' : cancelText || 'CANCEL'}
            </Button>
          ) : null}

          {!noConfirm ? (
            <Button
              solidBlue={!destructive}
              solidRed={destructive}
              noPadding
              className="px-3 min-w-20 lg:min-w-24"
              onClick={onConfirm}
              loading={confirmLoading}
              disabled={forceScrollBottom && !reachedBottom}
            >
              {confirmLoading ? confirmLoadingText || 'CONFIRMING' : confirmText || 'CONFIRM'}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default PopUpConfirmation;
